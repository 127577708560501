import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ButtonLoading from 'components/common/loading/components/buttonLoading/buttonLoading';
import styles from './buttonModern.styles.pcss';

const CLASS_BY_STYLE = {
  add: styles.buttonModernAdd,
  export: styles.buttonModernExport,
  cancel: styles.buttonModernCancel,
  delete: styles.buttonModernDelete,
  select: styles.buttonModernSelect,
  highlight: styles.buttonModernHighlight,
};

const CLASS_BY_SIZE = {
  default: styles.buttonModernSizeDefault,
  small: styles.buttonModernSizeSmall,
  medium: styles.buttonModernSizeMedium,
};

const CLASS_BY_ICON = {
  default: null,
  true: styles.buttonModernWithIcon,
};

const ButtonModern = ({
  className,
  styleType,
  onClick,
  isLoading,
  disabled,
  children,
  dataTest,
  size,
  withIcon,
  ...props
}) => (
  <button
    type="button"
    className={cx(
      styles.buttonModern,
      CLASS_BY_STYLE[styleType],
      CLASS_BY_SIZE[size],
      CLASS_BY_ICON[withIcon],
      className,
      isLoading && styles.buttonModernLoading,
    )}
    disabled={isLoading || disabled}
    onClick={onClick}
    data-test={dataTest}
    {...props}
  >
    {isLoading && (
      <div className={styles.buttonModernLoadingContainer}>
        <ButtonLoading useDarkSpinner={[ButtonModern.STYLE.SELECT].includes(styleType)} />
      </div>
    )}
    {children}
  </button>
);

ButtonModern.propTypes = {
  className: PropTypes.string,
  styleType: PropTypes.oneOf(['add', 'export', 'cancel', 'delete', 'select']),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  dataTest: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'default', 'by-content']),
  withIcon: PropTypes.oneOf(['default', true]),
  label: PropTypes.string,
};

ButtonModern.defaultProps = {
  className: '',
  styleType: '',
  disabled: false,
  isLoading: false,
  dataTest: '',
  size: 'default',
  withIcon: 'default',
  onClick: () => null,
  label: '',
};

ButtonModern.STYLE = {
  ADD: 'add',
  EXPORT: 'export',
  CANCEL: 'cancel',
  DELETE: 'delete',
  SELECT: 'select',
  HIGHLIGHT: 'highlight',
};

ButtonModern.SIZE = {
  DEFAULT: 'default',
  SMALL: 'small',
  MEDIUM: 'medium',
};

export default ButtonModern;
